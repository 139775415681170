import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactNode, useMemo, useState } from "react";
import { Website } from "components/Svg";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Transitions from "components/Transitions";
import { useShow } from "hooks/useShow";
import ecoImgIcon from "./ecoImg";
import _ from "lodash";

const BanBox = styled(Stack)`
  width: 100%;
  background: #fff;
  .swiper-pagination {
    bottom: 0;
  }
  .swiper-pagination-bullet-active {
    background-color: #4c67ef;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const Containter = styled(Stack)`
  width: 1200px;
  padding: 100px 30px 300px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "56px 20px 140px 20px",
    },
  })};
`;

const EcoChip = styled(Chip)<{ ecolor: string }>`
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  font-family: Arboria-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: ${({ ecolor }) => ecolor};
`;

const PcBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const MBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const CardItem = styled(Stack)`
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 20px;
  height: 385px;
  &:hover {
    border: 1px solid #4c67ef;
    background: #4c67ef;
    .card-title {
      color: #fff;
    }
    .card-desc {
      color: #fff;
    }
    .website-text {
      color: #fff;
      text-decoration: underline;
    }
    .website-svg path {
      fill: #fff;
    }
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const CardTitle = styled(Typography)`
  color: #000;
  font-family: Arboria-Medium;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
`;

const CardDesc = styled(Typography)`
  color: #000;
  font-family: Arboria-Book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const AvatarCon = styled(Avatar)`
  border: 1px solid #c4c4c4;
  width: 88px;
  height: 88px;
`;

export enum EcoType {
  AVS = "AVS",
  Operator = "Operator",
  Chain = "Chain",
  Protocol = "Protocol",
}

interface CardItem {
  icon: ReactNode;
  title: string;
  desc: string;
  chipType: EcoType[];
  link: string;
}

const Card = () => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const { ref, isShow } = useShow();
  const [curType, setCurType] = useState<EcoType | "">("");
  const changeType = (type: EcoType | "") => {
    setCurType(type);
  };
  const ecoConf: {
    [type: string]: { name: EcoType; color: string };
  } = {
    AVS: { name: EcoType.AVS, color: "#4C67EF" },
    Operator: { name: EcoType.Operator, color: "#FF873E" },
    Chain: { name: EcoType.Chain, color: "#A276FF" },
    Protocol: { name: EcoType.Protocol, color: "#00E0FF" },
  };

  const cardData: CardItem[] = [
    {
      icon: <AvatarCon src={ecoImgIcon?.Bracle} />,
      title: "Bracle",
      desc: "Bracle Network is an oracle built for BTC and the wider blockchain ecosystem. Using Oglayer’s AVS and the cost efficient cryptonomic security of restaked assets, Bracle utilizes MPC and ZK proof to trace computed results while saving computing cycles.",
      chipType: [EcoType.AVS],
      link: "https://bracle.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Chainbase} />,
      title: "Chainbase",
      desc: "Chainbase is the world’s largest omnichain data network designed to integrate all blockchain data into a unified ecosystem, providing an open and transparent data interoperability layer for the AI era.",
      chipType: [EcoType.AVS, EcoType.Operator],
      link: "https://chainbase.com/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.InfStones} />,
      title: "InfStones",
      desc: "The Ultimate Web3 Infrastructure Platform(TM) supports over 80+ blockchain protocols. Staking, Node Management, API Gateway, and Blockwatch.",
      chipType: [EcoType.Operator],
      link: "https://infstones.com",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Hashkeycloud} />,
      title: "Hashkey Cloud",
      desc: "HashKey Cloud, as a leading provider of global Web3 infrastructure and a core member of the HashKey Group, focuses on delivering professional, stable, and secure blockchain services to clients worldwide.",
      chipType: [EcoType.Operator],
      link: "https://hashkey.cloud",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.RockX} />,
      title: "RockX",
      desc: "RockX is a blockchain fintech company that helps our customers embrace web 3.0 effortlessly through the development of innovative products and infrastructure.",
      chipType: [EcoType.Operator],
      link: "https://rockx.com/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Edgevana} />,
      title: "Edgevana",
      desc: "Edgevana Liquid Staking is a decentralized protocol that enables users to contribute to the security of the Solana network by staking SOL and receiving a cryptographic receipt token called edgeSOL. edgeSOL represents ownership of staked SOL that's delegated to the top validators running on Edgevana.",
      chipType: [EcoType.Operator],
      link: "https://www.edgevana.com/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.CoinSummer} />,
      title: "CoinSummer",
      desc: "CoinSummer Labs was founded with a strong belief in Satoshi Nakamoto's codes and vision. From that moment, our investment research and development teams delved deep into Onchain Analysis and engaged in Secondary Marlket Trading to capitalize on the immense opportunities within the crypto space. ",
      chipType: [EcoType.Operator],
      link: "https://coinsummer.io/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Staketab} />,
      title: "Staketab",
      desc: "Staketab is a staking provider that champions credibility, individual approach and immediate expansion of new cryptos offered by the market. You will never be missed in huge dozens of crypto updates. We are refining the most profitable options for you and launching corresponding staking services, block explorers and data aggregators. Join us and enjoy staking profits!",
      chipType: [EcoType.Operator],
      link: "https://staketab.com/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.DAIC} />,
      title: "DAIC",
      desc: "DAIC is a non-custodial staking and Web3 infrastructure provider, investor and researcher.Coinage x DAIC is a trusted community validator, setting a new standard for community-driven staking solutions, ensuring a more transparent, secure, and inclusive Web3 future!",
      chipType: [EcoType.Operator],
      link: "https://daic.capital/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BNBSmartChain} />,
      title: "BNB Smart Chain",
      desc: "BNB Smart Chain is a high-performance blockchain platform designed to enable the development of scalable and user-friendly decentralized applications (DApps), particularly in the decentralized finance (DeFi) space.",
      chipType: [EcoType.Chain],
      link: "https://www.bnbchain.org/en",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Bitlayer} />,
      title: "Bitlayer",
      desc: "Bitlayer proposes a Turing-complete computing layer solution based on the BitVM paradigm and zero-knowledge(ZK) proofs, while fully inheriting Bitcoin’s security.",
      chipType: [EcoType.Chain],
      link: "https://www.bitlayer.org/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.MerlinChain} />,
      title: "MerlinChain",
      desc: 'Merlin Chain is a Bitcoin Layer 2 solution that integrates several advanced technologies: the ZK-Rollup network, decentralized oracle network, Data Availability, and on-chain BTC fraud-proof modules. Its primary objective is to enhance Bitcoin\'s native assets, protocols, and products on Layer 1 by leveraging its Layer 2 network, and ultimately achieve the goal of "Make Bitcoin Fun Again."',
      chipType: [EcoType.Chain],
      link: "https://merlinchain.io/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BounceBit} />,
      title: "BounceBit",
      desc: "BounceBit pioneers CeDeFi infrastructure, offering institutional-grade yield products, restaking use cases, and CeDeFi as a service, making high-yield opportunities accessible to all.",
      chipType: [EcoType.Chain],
      link: "https://bouncebit.io/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.B2network} />,
      title: "B² Network",
      desc: "B² Network is a Layer-2 solution that bolsters transaction speed and broadens application diversity without sacrificing security. B² Network is the first zero-knowledge proof verification commitment rollup on Bitcoin. ",
      chipType: [EcoType.Chain],
      link: "https://bsquared.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Core} />,
      title: "Core",
      desc: "Core DAO started as a community looking for better solutions, and that’s what it remains. With principles grounded in the premises of both Bitcoin and Ethereum, our power comes from embracing multiple ideas and communities.",
      chipType: [EcoType.Chain],
      link: "https://coredao.org",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BEVM} />,
      title: "BEVM",
      desc: "BEVM is the leading and most secure Bitcoin L2 that focuses on PoW hashrate RWA, and is also the only BTC layer2 invested and backed by BITMAINtech.",
      chipType: [EcoType.Chain],
      link: "https://bevm.io",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Botanix} />,
      title: "Botanix",
      desc: "Botanix Labs is building the infrastructure to transform Bitcoin from the best money ever made to the programmable layer that will serve as the basis for the future of finance. Our approach is to build the first fully decentralized EVM-equivalent Layer 2 on Bitcoin, where the ease and versatility of EVM meets the decentralization and security of Bitcoin.",
      chipType: [EcoType.Chain],
      link: "https://botanixlabs.xyz/en/testnet",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.RunesChain} />,
      title: "RunesChain",
      desc: "Welcome to Runes Chain, where cutting-edge technologies converge to revolutionize the landscape of Layer 2 Bitcoin solutions. Our platform integrates AvailDA for decentralized availability, Settlement through Arbitrum, and employs an EVM-compatible optimistic rollup as part of our robust Tech Stack.",
      chipType: [EcoType.Chain],
      link: "https://runeschain.ai/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.TunaChain} />,
      title: "Tuna",
      desc: "Tuna Chain pioneers as the first modular Layer 2 on Bitcoin, featuring a ZK-OP hybrid approach that  seamlessly incorporates Ethereum's EVM capabilities.",
      chipType: [EcoType.Chain],
      link: "https://tunachain.io/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.ArchNetwork} />,
      title: "Arch Network",
      desc: "Arch Network establishes the foundational pillars for creating a vibrant ecosystem directly on Bitcoin—smart contract programmability and trustless interoperability—paving the way for a new wave of dApps and protocols on Bitcoin's base layer. ",
      chipType: [EcoType.Chain],
      link: "https://www.arch.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BOB} />,
      title: "BOB",
      desc: "BOB allows devs familiar with EVM to start building on Bitcoin now. Be the fastest to market, powered by the best-in-class EVM tooling, wallets, analytics, and key blockchain infrastructure, as well as a tailor-made Bitcoin developer suite. ",
      chipType: [EcoType.Chain],
      link: "https://www.gobob.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.AILayer} />,
      title: "AILayer",
      desc: "The first Bitcoin Layer 2 aimed at supporting the mass adoption of AI applications, with the goal of advancing the deep integration of Web3 and AI.",
      chipType: [EcoType.Chain],
      link: "https://ailayer.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Bitfinity} />,
      title: "Bitfinity",
      desc: "Bitfinity Network is a next-gen Bitcoin Layer 2 solution, and we're keen on potential partnerships as we approach our mainnet launch.Bitfinity is EVM compatible, enabling builders to deploy Bitcoin-enabled dApps using Solidity, thereby unlocking BTC liquidity for DeFi, Gaming, and NFTs. ",
      chipType: [EcoType.Chain],
      link: "https://bitfinity.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Mantle} />,
      title: "Mantle",
      desc: "Mass adoption of decentralized & token-governed technologies. With Mantle Network, Mantle Treasury, and token holder-governed product initiatives. ",
      chipType: [EcoType.Chain],
      link: "https://www.mantle.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Arbitrum} />,
      title: "Arbitrum",
      desc: "Arbitrum One—a leading Ethereum Layer-2 scaling solution initially developed by Offchain Labs—offers ultra-fast, low-cost transactions with security derived from Ethereum through Optimistic Rollup technology. ",
      chipType: [EcoType.Chain],
      link: "https://arbitrum.foundation/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.MapProtocol} />,
      title: "MapProtocol",
      desc: "MAP Protocol is the gateway for users to enter the Bitcoin ecosystem and interact with the entire interoperable blockchain world. Here are some simple steps to help users quickly experience this continuously evolving technology. ",
      chipType: [EcoType.Protocol],
      link: "https://www.mapprotocol.io/en",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.LorenzoProtocol} />,
      title: "Lorenzo Protocol",
      desc: "Lorenzo is the Bitcoin Liquidity Finance Layer. With the global adoption of Bitcoin growing, there is a significantly higher demand for Bitcoin liquidity, by ways of L2s, DeFi, trading, etc., and platforms are promising yield in exchange for Bitcoin liquidity.  ",
      chipType: [EcoType.Protocol],
      link: "https://www.lorenzo-protocol.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Bedrock} />,
      title: "Bedrock",
      desc: "Bedrock is a multiple asset liquid restaking protocol, backed by non-custodial solution designed in partnership with RockX, a longstanding blockchain infrastructure company with strong roots in crypto staking. ",
      chipType: [EcoType.Protocol],
      link: "https://www.bedrock.technology/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.SolvFinance} />,
      title: "Solv Finance",
      desc: 'Solv Protocol is revolutionizing yield aggregation and liquidity management by tokenizing and consolidating high-quality yields from diverse sources. At the heart of Solv’s offerings are "Liquid Yield Tokens," designed to unlock a wide array of earning opportunities across the blockchain ecosystem. ',
      chipType: [EcoType.Protocol],
      link: "https://solv.finance/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.FBTC} />,
      title: "FBTC",
      desc: "FBTC will be deployed through a decentralized framework that involves multiple institutions overseeing the reserves, issuance processes, and a multi-stakeholder governance structure.",
      chipType: [EcoType.Protocol],
      link: "https://fbtc.com/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.UniRouter} />,
      title: "UniRouter",
      desc: "UniRouter introduces liquid staking, cross-chain asset transfers and EVM-compatible messaging functionality to the Bitcoin network.",
      chipType: [EcoType.Protocol],
      link: "https://www.unirouter.io",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.XLink} />,
      title: "Xlink",
      desc: "Bridging Bitcoin and the world. XLink is Bitcoin bridge by ALEXLabBTC",
      chipType: [EcoType.Protocol],
      link: "https://www.xlink.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.capyfinance} />,
      title: "Capy Finance",
      desc: "Capy Finance supports lending and borrowing for Bitcoins, Inscriptions (BRC-20, SRC-20), Runes, and more asset types across multiple chains.",
      chipType: [EcoType.Protocol],
      link: "https://www.capyfinance.com/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BoolNetwork} />,
      title: "Bool Network",
      desc: "Bool Network is an open, decentralized and secure Bitcoin verification layer powered by MPC-based distributed key management over evolving hidden committees.",
      chipType: [EcoType.Protocol],
      link: "https://bool.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.ZkMe} />,
      title: "ZkMe",
      desc: "zkMe builds Identity Oracles that leverage the power of zero-knowledge-proofs to enable secure, self-sovereign and private credential verifications.",
      chipType: [EcoType.Protocol],
      link: "https://zk.me/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.MasterProtocol} />,
      title: "Master Protocol",
      desc: "The Master Protocol is a user and asset aggregator deeply integrated into the BTC ecosystem, unlocking liquidity and maximizing yields. We aim to achieve this through our Master Yield Platform and the development of both the Master Liquid Staking Solution (featuring Botanix).",
      chipType: [EcoType.Protocol],
      link: "https://www.masterprotocol.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.OpenBit} />,
      title: "Openbit",
      desc: "OpenBit efficiently enables secure cross-chain circulation and DeFi services for BTC and non-BTC assets.",
      chipType: [EcoType.Protocol],
      link: "https://openbit.network/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.GateWeb3} />,
      title: "Gate Web3",
      desc: "Gate Web3 Wallet is a decentralized, non-custodial wallet that supports multi-chain aggregation and is available across multiple platforms (mobile, web, and plugin).",
      chipType: [EcoType.Protocol],
      link: "https://www.gate.io/web3",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.restake} />,
      title: "Restake",
      desc: "Restake stands as the institutional architect for blockchain ecosystems, powering growth through robust infrastructure, funding and impactful partnerships.",
      chipType: [EcoType.Protocol],
      link: "https://www.restake.net/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.AvalonFinance} />,
      title: "Avalon Finance",
      desc: "Avalon Finance strives to become the best decentralized lending protocol on BTC layer 2. It has been launched on Merlin, Bitlayer, CoreDAO, BNB Chain and Arbitrum. ",
      chipType: [EcoType.Protocol],
      link: "https://www.avalonfinance.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.bitcow} />,
      title: "BitCow",
      desc: "bitCow is an AMM offering stable swap and automated concentrated liquidity on BTC L2s.",
      chipType: [EcoType.Protocol],
      link: "https://bitcow.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.MathWallet} />,
      title: "MathWallet",
      desc: "MathWallet is a powerful universal crypto wallet which supports multi-chain tokens and dApps on both mobile / browser extension / web / hardware wallet.",
      chipType: [EcoType.Protocol],
      link: "https://mathwallet.org/en-us/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.zearn} />,
      title: "Zearn",
      desc: "Zearn is an omnichain liquid (re)staking protocol that supports multiple blockchain ecosystems. It allows users to stake tokens and earn rewards without maintaining a staking infrastructure.",
      chipType: [EcoType.Protocol],
      link: "https://www.zearn.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BitSmiley} />,
      title: "bitSmiley",
      desc: "bitSmiley is a protocol based on the Bitcoin blockchain under the Fintegra framework. It consists of three main components: a decentralized overcollateralized stablecoin protocol, a native trustless lending protocol, and a derivatives protocol.",
      chipType: [EcoType.Protocol],
      link: "https://twitter.com/bitsmiley_labs",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.Bit} />,
      title: ".bit",
      desc: "The d.id team is building protocols for proof of humanity and achievement network, connecting every human. Own your ID and achievement through our blockchain-powered protocol network, and be ready for the next societal breakthrough.",
      chipType: [EcoType.Protocol],
      link: "https://d.id/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BIMA} />,
      title: "BIMA",
      desc: "BIMA allows LST/LRT holders to leverage their tokens as collateral to mint $USBD, seamlessly integrating Bitcoin's value into DeFi. Users can preserve their BTC value while accessing new yield opportunities like liquidity provision and lending.",
      chipType: [EcoType.Protocol],
      link: "https://bima.money/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.PumpBTC} />,
      title: "PumpBTC",
      desc: "PumpBTC, a liquid staking protocol on babylonlabs, offers a 1:1 peg to BTC with instant liquidity and native yield, reimagining WBTC to maximize returns in the Babylon ecosystem.",
      chipType: [EcoType.Protocol],
      link: "https://mainnet.pumpbtc.xyz/",
    },
    {
      icon: <AvatarCon src={ecoImgIcon?.BitFi} />,
      title: "BitFi",
      desc: "BitFi, a revolutionary digital financial platform. BitFi integrates the strengths of secure staking mechanisms with innovative synthetic assets to create a robust and versatile ecosystem.",
      chipType: [EcoType.Protocol],
      link: "https://bitfi.org/",
    },
  ];

  const curCardData: CardItem[] = useMemo<CardItem[]>(() => {
    let arr = cardData;
    if (curType) {
      arr = cardData.filter((item) => item.chipType?.includes(curType));
    }
    return arr;
  }, [cardData, curType]);

  const curMobileCardData: CardItem[][] = useMemo<CardItem[][]>(() => {
    return _.chunk(curCardData, 5);
  }, [curCardData]);

  const CardItemEl = ({ item }: { item: CardItem }) => {
    return (
      <CardItem
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Box>
          <Box>{item.icon}</Box>
          <Box mt={20}>
            <CardTitle className="card-title">{item.title}</CardTitle>
          </Box>
          <Box mt={10}>
            <CardDesc className="card-desc">{item.desc}</CardDesc>
          </Box>
        </Box>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          width={"100%"}
        >
          <Box>
            {item.chipType.map((chipItem: EcoType, index: number) => (
              <EcoChip
                label={chipItem}
                ecolor={`${ecoConf[chipItem]?.color}`}
              />
            ))}
          </Box>

          <NavLink target="_blank" to={item.link}>
            <Stack
              justifyContent="center"
              className="website-hover"
              alignItems="center"
              direction="row"
            >
              <CardDesc mr={6} className="website-text">
                WEBSITE
              </CardDesc>
              <Website></Website>
            </Stack>
          </NavLink>
        </Stack>
      </CardItem>
    );
  };

  return (
    <BanBox
      ref={ref}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <Containter>
        <Stack
          px={{ xs: "20px", md: "0px" }}
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
        >
          <Box mr={10}>
            <EcoChip
              onClick={() => {
                changeType("");
              }}
              label="All"
              ecolor="#4C67EF"
            />
          </Box>
          {Object.keys(ecoConf).map((item: string, index: number) => (
            <Box key={index} mr={10}>
              <EcoChip
                onClick={() => {
                  changeType(item as EcoType);
                }}
                label={item}
                ecolor={ecoConf[item].color}
              />
            </Box>
          ))}
        </Stack>
        <Transitions isUp={false} delay={600} isShow={isShow}>
          <Box overflow={"hidden"}>
            {!downToMd ? (
              <PcBox mt={60}>
                <Grid container spacing={30}>
                  {curCardData.map((item: CardItem, index: number) => (
                    <Grid key={index} xs={4} md={4}>
                      <CardItemEl item={item}></CardItemEl>
                    </Grid>
                  ))}
                </Grid>
              </PcBox>
            ) : (
              <MBox p={20} mt={40}>
                <Swiper
                  onSwiper={setSwiperRef}
                  initialSlide={0}
                  spaceBetween={20}
                  pagination={true}
                  modules={[Pagination]}
                  autoHeight={true}
                  style={{ width: "100%" }}
                >
                  {curMobileCardData.map(
                    (itemO: CardItem[], indexO: number) => (
                      <SwiperSlide key={indexO}>
                        {itemO.map((item: CardItem, index: number) => (
                          <Box mb={20}>
                            <CardItemEl key={index} item={item}></CardItemEl>
                          </Box>
                        ))}
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </MBox>
            )}
          </Box>
        </Transitions>
      </Containter>
    </BanBox>
  );
};

export default Card;
