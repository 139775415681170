import { styled } from "@mui/material/styles";
import { Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import { getTvl } from "utils/api";
import { amountFormat } from "utils/format";
import { useState, useCallback, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CountUp from "react-countup";

const TvlBox = styled(Stack)`
  width: 100%;
  height: 118px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      // padding: "20px",
    },
  })};
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  position: relative;
  z-index: 2;
  border-radius: 64px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 15.34%,
    rgba(255, 255, 255, 0.12) 100%
  );
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      padding: "0 5px",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 70%;
  }
`;

const TvlValue = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 36px;
  text-align: center;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
  })};
`;

const TvlLabel = styled(Typography)`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Arboria-Book;
  font-size: 16px;
  font-weight: 400;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })};
`;

const TvlCard = styled(Stack)`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(40px);
  padding: 20px;
  width: 100%;
  margin-top: 200px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginTop: "170px",
    },
  })};
`;

const Tvl = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const [totalTvl, setTotalTvl] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const initTvl = async () => {
    try {
      let tvlRes = await getTvl();
      if (tvlRes) {
        setTotalTvl(tvlRes.result?.tvl || 0);
        setTotalCount(tvlRes.result?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const init = useCallback(async () => {
    await initTvl();
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <TvlBox justifyContent="center" alignItems="center" direction="row">
      {upToMd ? (
        <Containter>
          <Grid height={"100%"} container spacing={0}>
            <Grid xs={6} md={6}>
              <Stack
                alignItems={"center"}
                justifyContent={"flex-end"}
                direction={"row"}
                height={"100%"}
              >
                <Stack pr={66} style={{ borderRight: "1px solid #7c7c7c" }}>
                  <TvlValue>
                    ${" "}
                    {totalTvl ? (
                      <CountUp start={0} end={totalTvl} decimal="," />
                    ) : (
                      "--"
                    )}
                  </TvlValue>
                  <TvlLabel>Total Restaked Tokens</TvlLabel>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={6} md={6}>
              <Stack
                alignItems={"center"}
                justifyContent={"flex-start"}
                direction={"row"}
                height={"100%"}
              >
                <Stack pl={66}>
                  <TvlValue>
                    {totalCount ? (
                      <CountUp start={0} end={totalCount} decimal="," />
                    ) : (
                      "--"
                    )}
                  </TvlValue>
                  <TvlLabel>Cumulative stakers</TvlLabel>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Containter>
      ) : (
        <>
          <TvlCard>
            <Stack>
              <TvlValue>
                ${" "}
                {totalTvl ? (
                  <CountUp start={0} end={totalTvl} decimal="," />
                ) : (
                  "0"
                )}
              </TvlValue>
              <TvlLabel>Total Restaked Tokens</TvlLabel>
              <TvlValue mt={30}>
                {totalCount ? (
                  <CountUp start={0} end={totalCount} decimal="," />
                ) : (
                  "0"
                )}
              </TvlValue>
              <TvlLabel>Cumulative stakers</TvlLabel>
            </Stack>
          </TvlCard>
        </>
      )}
    </TvlBox>
  );
};

export default Tvl;
